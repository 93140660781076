@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?cke6r');
  src:  url('../fonts/icomoon.eot?cke6r#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?cke6r') format('truetype'),
    url('../fonts/icomoon.woff?cke6r') format('woff'),
    url('../fonts/icomoon.svg?cke6r#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
    font-display: swap;  
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-agents:before {
  content: "\e900";
}
.icon-auctions:before {
  content: "\e901";
}
.icon-bidders:before {
  content: "\e902";
}
.icon-dashboard:before {
  content: "\e903";
}
.icon-sales-properties:before {
  content: "\e904";
}
